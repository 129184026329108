// 配置编译环境和线上环境之间的切换

const env = process.env;
var baseUrl = '';
if (env.NODE_ENV == "development") {
  baseUrl = "http://192.168.3.134:8080";
} else {
  baseUrl = "https://api.zkzktech.cn";
}

export { baseUrl,env };
