import axios from 'axios'
import { baseUrl } from '@/config/env.js'
axios.defaults.timeout = 30000;
// 返回其他状态吗
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status < 500; // 默认的
};

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
});
export default instance;