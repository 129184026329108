import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
import "@/assets/icon/iconfont.css"
// 引入全局css
import "./assets/scss/style.scss";
import axios from '@/axios/request.js'

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.prototype.$http = axios;


Vue.config.productionTip = false;
Vue.use(dataV);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
